import React from 'react';
import {Link} from 'react-router-dom';
import brand from '../../../assets/img/brand.png';
import {useDispatch, useSelector} from "react-redux";
import * as userActions from '../../../redux/users/user.actions';
import * as userReducer from '../../../redux/users/user.reducers';
import * as userUtil from '../../util/userUtil';
import * as orderReducer from '../../../redux/orders/order.reducer';

let Navbar = () => {
    let dispatch = useDispatch();

    let orderInfo = useSelector((state) => {
        return state[orderReducer.orderFeatureKey];
    });

    let {cartItems} = orderInfo;

    let userInfo = useSelector((state) => {
        return state[userReducer.userFeatureKey];
    });

    let {isAuthenticated , user} = userInfo;

    console.log(userInfo,'navbarpage')

    let clickLogOut = () => {
        dispatch(userActions.logOutUser());
    };

    let isLoggedIn = () => {
        return userUtil.isLoggedIn();
    };

    let afterLogin = (
       <React.Fragment>
           <li className="nav-item">
               <div className="nav-link">
               {/* <Link to="/users/profile" className="nav-link"> */}
                  {/* <img src="" alt="Hi" width="25" height="25" className="rounded-circle"/> {user.name} */}

                  {/* </Link> */}
                  <h5>{user?.name}</h5>
                  </div>
           </li>
           <li className="nav-item">
               <Link to="/" className="nav-link" onClick={clickLogOut} style={{color:"#96540c"}}>
                  <i className="fa fa-sign-out-alt"/> LogOut</Link>
           </li>
       </React.Fragment>
    );

    let beforeLogin = (
        <React.Fragment>
            <li className="nav-item">
                <Link to="/users/register" className="nav-link" style={{color:"#96540c"}}>Register</Link>
            </li>
            <li className="nav-item">
                <Link to="/users/login" className="nav-link" style={{color:"#96540c"}}>Login</Link>
            </li>
        </React.Fragment>
    );

    return (
        <React.Fragment >
            <nav className="navbar   navbar-expand-sm navcolor" >

                   <Link to="/" className="navbar-brand">
                       <img src={brand} alt="" width="80" height="50"/>
                       <figcaption style={{color:"#96540c", fontSize : "15px"}}>Vedic Organics</figcaption>
                   </Link>
                   <div className="container">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/products/medicine" className="nav-link" style={{color:"#96540c"}}>Panchagavya Medicine</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/products/cosmetics" className="nav-link" style={{color:"#96540c"}}>Panchagavya Cosmetics</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/products/daily" className="nav-link" style={{color:"#96540c"}}>Panchagavya Daily</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/products/upload" className="nav-link" style={{color:"#96540c"}}>Upload</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/orders/cart" className="nav-link" style={{color:"#96540c"}}>
                                    <i className="fa fa-shopping-cart"/>
                                    <span className="badge badge-danger">{cartItems.length}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/orders/list" className="nav-link" style={{color:"#96540c"}}>Orders</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto" >
                            {
                                isLoggedIn() ? afterLogin : beforeLogin
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
};
export default Navbar;