import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userReducer from "../../redux/users/user.reducers";
import * as orderReducer from "../../redux/orders/order.reducer";
import * as orderActions from "../../redux/orders/order.actions";
import Spinner from "../../layout/util/spinner/Spinner";
import StripeCheckout from "react-stripe-checkout";
import stripeImage from "../../assets/img/uibrains.png";
import { useHistory } from "react-router-dom";
import * as userActions from "../../redux/users/user.actions";

let CheckOut = () => {
  let dispatch = useDispatch();
  let history = useHistory();

  let userInfo = useSelector((state) => {
    return state[userReducer.userFeatureKey];
  });

  let cartInfo = useSelector((state) => {
    return state[orderReducer.orderFeatureKey];
  });
  console.log(userInfo);
  let [enableAddress, setEnableAddress] = useState(false);
  let { loading, user } = userInfo;
  let { cartItems } = cartInfo;
  console.log(cartItems);
  let [address, setAddress] = useState({
    flat: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    mobile: "",
  });
  

  useEffect(() => {
    setAddress({
      flat: user && user.address ? user.address.flat : "",
      street: user && user.address ? user.address.street : "",
      landmark: user && user.address ? user.address.landmark : "",
      city: user && user.address ? user.address.city : "",
      state: user && user.address ? user.address.state : "",
      country: user && user.address ? user.address.country : "",
      pin: user && user.address ? user.address.pin : "",
      mobile: user && user.address ? user.address.mobile : "",
    });
  }, [user]);

  let updateInputAddress = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };
  let submitUpdateAddress = () => {
    dispatch(userActions.updateAddress(address));
    setEnableAddress(false);
      // history.push('/orders/checkout')
  };
  let calcTotal = () => {
    let total = 0;
    for (let cartItem of cartItems) {
      total += cartItem.price * cartItem.qty;
    }
    return total;
  };

  let calcTax = () => {
    let tax = Number(process.env.REACT_APP_PRODUCT_TAX);
    return (calcTotal() * tax) / 100;
  };

  let calcGrandTotal = () => {
    return calcTotal() + calcTax();
  };

  let clickPayment = (token) => {
    let items = cartItems.map((item) => {
      return {
        name: item.name,
        brand: item.brand,
        price: item.price,
        qty: item.qty,
      };
      
    });
    
    let order = {
      items: items,
      tax: calcTax(),
      total: calcTotal(),
    };
     console.log(order.items,'ooooooooooo');

    let product = {
      price: Number(calcGrandTotal()) * 100,
      name: "Products from  Vedic Organics",
    };
    let body = {
      token,
      product,
    };
    dispatch(orderActions.makeStripePayment(body, history, order));
  };

  return (
    <React.Fragment>
      <section className="bg-brown text-dark p-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3>
                <i className="fa fa-check-circle" /> Checkout Your Items
              </h3>
            </div>
          </div>
        </div>
      </section>

      <React.Fragment>
        <React.Fragment>
          <section>
            <div className="mt-3 mb-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header bg-dark text-brown">
                        <p className="h4">
                          Billing Address
                        </p>
                      </div>
                      <div className="card-body">
                        <ul className="list-group">
                          <li className="list-group-item bg-brown text-dark">
                          <section>
              <div className="container mt-2">
                <div className="row">
                  {/* <div className="col-md-3">
                    <img
                      src={user.avatar}
                      className="img-fluid rounded-circle profile-img"
                    />
                  </div> */}
                  <div className="col md-5">
                    <div className="card">
                      <div className="card-header bg-dark text-brown">
                        <p className="h4">Your Information</p>
                      </div>
                      <div className="card-body bg-brown">
                        <ul className="list-group">
                          <li className="list-group-item">
                            NAME :{" "}
                            <span className="font-weight-bold">
                              {user?.name}
                            </span>
                          </li>
                          <li className="list-group-item">
                            Email :{" "}
                            <span className="font-weight-bold">
                              {user?.email}
                            </span>
                          </li>
                         
                            {/* <li className="list-group-item">
                              Mobile :{" "}
                              <span className="font-weight-bold">
                                {address.mobile}
                              </span>
                            </li> */}
                          
                        </ul>
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-header bg-dark text-brown">
                        <span className="h4">Billing Address</span>
                        <div className="custom-control custom-switch float-right">
                          <input
                            onChange={(e) => setEnableAddress(e.target.checked)}
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          >
                            Enable Address
                          </label>
                        </div>
                      </div>
                      {user.address && !enableAddress && (
                        <div className="card-body bg-brown">
                          <ul className="list-group">
                            <li className="list-group-item">
                              Flat : {address.flat}
                            </li>
                            <li className="list-group-item">
                              Street : {address.street}
                            </li>
                            <li className="list-group-item">
                              Landmark : {address.landmark}
                            </li>
                            <li className="list-group-item">
                              City : {address.city}
                            </li>
                            <li className="list-group-item">
                              State : {address.state}
                            </li>
                            <li className="list-group-item">
                              Country : {address.country}
                            </li>
                            <li className="list-group-item">
                              PinCode : {address.pin}
                            </li>
                            <li className="list-group-item">
                              Mobile : {address.mobile}
                            </li>
                          </ul>
                        </div>
                      )}
                     
                        <div className="card-body bg-brown">
                          <form onSubmit={submitUpdateAddress}>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  Flat
                                </span>
                              </div>
                              <input
                                name="flat"
                                value={address.flat}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  Street
                                </span>
                              </div>
                              <input
                                name="street"
                                value={address.street}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  Landmark
                                </span>
                              </div>
                              <input
                                name="landmark"
                                value={address.landmark}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  City
                                </span>
                              </div>
                              <input
                                name="city"
                                value={address.city}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  State
                                </span>
                              </div>
                              <input
                                name="state"
                                value={address.state}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  Country
                                </span>
                              </div>
                              <input
                                name="country"
                                value={address.country}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  Pincode
                                </span>
                              </div>
                              <input
                                name="pin"
                                value={address.pin}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-dark text-brown">
                                  Mobile
                                </span>
                              </div>
                              <input
                                name="mobile"
                                value={address.mobile}
                                onChange={updateInputAddress}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div>
                              <input
                                type="submit"
                                className="btn btn-dark text-brown btn-sm"
                                value="Update"
                              />
                            </div>
                          </form>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </section>                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-header bg-dark text-brown">
                        <p className="h4">Payment Details</p>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Cash On Delivery
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios2"
                              value="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Credit Card Payment
                            </label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header bg-dark text-brown">
                        <p className="h4">Your Cart</p>
                      </div>
                      <div className="card-body">
                        {cartItems.length > 0 && (
                          <ul className="list-group">
                            {cartItems.map((cartItem) => {
                              return (
                                <li
                                  key={cartItem.id}
                                  className="list-group-item"
                                >
                                  <div className="row align-items-center">
                                    <div className="col-md-2">
                                      <img
                                        src={cartItem.Image}
                                        alt=""
                                        className="img-fluid"
                                        width="100"
                                        height="300"
                                      />
                                    </div>
                                    <div className="col-md-8">
                                      <small>{cartItem.name}</small>
                                      <br />
                                      <small>
                                        &#8377; {cartItem.price.toFixed(2)}
                                      </small>
                                      <br />
                                      <small>Qty : {cartItem.qty}</small>
                                      <br />
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <ul className="list-group mt-2">
                          <li className="list-group-item bg-brown">
                            Total :{" "}
                            <span className="font-weight-bold">
                              &#8377;{calcTotal().toFixed(2)}
                            </span>
                          </li>
                          <li className="list-group-item bg-brown">
                            Tax :{" "}
                            <span className="font-weight-bold">
                              &#8377;{calcTax().toFixed(2)}
                            </span>
                          </li>
                          <li className="list-group-item bg-brown">
                            Grand Total :{" "}
                            <span className="font-weight-bold">
                              &#8377;{calcGrandTotal().toFixed(2)}
                            </span>
                          </li>
                        </ul>
                        <StripeCheckout
                          token={clickPayment}
                          stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                          name="Stripe Payment"
                          amount={calcGrandTotal() * 100}
                          description="Payments with Stripe"
                          currency="INR"
                          image={stripeImage}
                        >
                          <button className="btn btn-danger btn-sm btn-block mt-3">
                            PAY &#8377; {calcGrandTotal().toFixed(2)}
                          </button>
                        </StripeCheckout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  );
};
export default CheckOut;
