import Axios from "axios";
import * as alertActions from '../alert/alert.actions';
import * as userUtil from '../../layout/util/userUtil';
import * as tokenUtil from '../../layout/util/tokenUtil';
// import {setAlert} from "../../redux/alert/alert.actions";

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';


export const LOGOUT_USER = 'LOGOUT_USER';

export const logOutUser = () => {
    return (dispatch) => {
        dispatch({type : LOGOUT_USER});
    };
};

export const registerUser = (user, history) => {
    return async (dispatch) => {
        try {
            console.log(user, history);
             dispatch({type : REGISTER_USER_REQUEST});
             let dataURL = `${process.env.REACT_APP_EXPRESS_SERVER}users/register`;
             let response = await Axios.post(dataURL , user);
             console.log(response.data)
            dispatch({type : REGISTER_USER_SUCCESS , payload : response.data});
            history.push('/users/login');
        }
        catch (error) {
            console.error(error.response);
            dispatch({type : REGISTER_USER_FAILURE , payload : error});
            // let errorList = error.response.data.errors;
            // for(let error of errorList){
            //     dispatch(alertActions.setAlert(error.msg , 'danger'))
            // }
        }
    }
}

export const loginUser = (user, history) => {
    return async (dispatch) => {
        try {
            dispatch({type : LOGIN_USER_REQUEST});
            let dataURL =  `${process.env.REACT_APP_EXPRESS_SERVER}users/login`;
            let response = await Axios.post(dataURL , user);
            if(response.data.msg === "success"){
                dispatch({type : LOGIN_USER_SUCCESS , payload : response.data});
                dispatch(alertActions.setAlert(response.data.msg , 'success'));
                // if(localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN)){
                //    dispatch(getUserInfo());
                // }
                history.push('/');
            } else {
                dispatch(alertActions.setAlert(response.data.msg  ,'danger'));
            }
        }
        catch (error) {
            console.error(error.response);
            // dispatch({type : LOGIN_USER_FAILURE , payload : error});
            // let errorList = error.response.data.errors;
            // for(let error of errorList){
            //     dispatch(alertActions.setAlert(error.msg , 'danger'))
            // }
        }
    }
}

export const getUserInfo = () => {
    return async (dispatch) => {
        try {
            // setting the token to request header to send to server
            if(userUtil.isLoggedIn()){
                tokenUtil.setAuthToken(userUtil.getToken());
            }
            dispatch({type : GET_USER_INFO_REQUEST});
            let dataURL = process.env.REACT_APP_EXPRESS_SERVER + +'users';
            let response = await Axios.get(dataURL);
            dispatch({type : GET_USER_INFO_SUCCESS , payload : response.data});
        }
        catch (error) {
            console.error(error.response);
            // dispatch({type : GET_USER_INFO_FAILURE , payload : error});
            // let errorList = error.response.data.errors;
            // for(let error of errorList){
            //     dispatch(alertActions.setAlert(error.msg , 'danger'))
            // }
        }
    }
}

export const updateAddress = (address,history) => {
    return async (dispatch) => {
        try {
            dispatch({type : UPDATE_ADDRESS_REQUEST});
            console.log('enteredaddress',address.flat)
            // alert('address',address)
            localStorage.setItem(address.flat,'flat');
            let dataURL = `${process.env.REACT_APP_EXPRESS_SERVER}users/address`;
            let response = await Axios.post(dataURL, address);
            dispatch({type : UPDATE_ADDRESS_SUCCESS , payload : response.data});
            // history.push('/orders/checkout')
        }
        catch (error) {
            console.error(error);
            // dispatch({type : UPDATE_ADDRESS_FAILURE , payload : error});
            // let errorList = error.response.data.errors;
            // for(let error of errorList){
            //     dispatch(alertActions.setAlert(error.msg , 'danger'))
            // }
        }
    }
}